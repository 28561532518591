angular.module('app')
    .component('transactions', {
        bindings: {
            embedded: "<",
            settlement: "<",
            setter: "&"
        },
        templateUrl: '/static/templates/transactions.html',
        controller: Transactions,
        controllerAs: 'ctrl'
    });

function Transactions($scope, $log, $stateParams, currentOrganisation, departmentService, Notification, Excel, accountService) {

    var vm = this;
    vm.paymentMethodReference = undefined;
    vm.transactionReference = undefined;
    vm.organisationReference = undefined;
    vm.paymentMethodName = undefined;
    vm.transactions = [];

    // search form fields
    vm.error = undefined;
    vm.fromDate = moment().subtract(7, "days").toDate();
    vm.toDate = moment().toDate();

    vm.pending = vm.settled = false;

    vm.loading = vm.loadingJournals = true;
    vm.pagedSet = {
        page: 0,
        limit: 100
    };
    vm.disableLoadMore = false;

    vm.loadMore = loadMore;
    vm.toggleJournal = toggleJournalEntries;
    vm.clearSearch = clearSearch;
    vm.search = search;
    vm.exportToCsv = exportToCsv;
    vm.$onInit = init;

    function init() {
        $log.debug("Transactions Controller initialized");

        if($stateParams.organisationReference) {
            vm.organisationReference = $stateParams.organisationReference;
        }
        if($stateParams.paymentMethodReference) {
            vm.paymentMethodReference = $stateParams.paymentMethodReference;

        }
        if($stateParams.paymentMethodName) {
            vm.paymentMethodName = $stateParams.paymentMethodName;

        }

        if(_.isUndefined(vm.organisationReference)) {
            currentOrganisation.getOrganisationReference()
                .then(function (organisationReference) {

                    departmentService.get(organisationReference)
                        .then(function (result) {

                            vm.organisation = result;
                            vm.organisationReference = vm.organisation.organisationReference;

                            listTransactions(0);

                        }, function (error) {
                            $log.error(error);
                        });
                });
        } else {
            listTransactions(0);
        }

    }

    function loadMore() {
        listTransactions(vm.pagedSet.page);
    }

    function listTransactions(page) {
        if (page === 0) {
            vm.transactions = [];
        }
        var params = getParams();
        getTransactionList(params);
    }

    function getTransactionList(params) {
        vm.disableLoadMore = vm.loading = vm.loadingMore = true;
        vm.error = undefined;

        if (!_.isEmpty(vm.transactionReference)) {
            params.transactionReference = vm.transactionReference;
        }

        return accountService.getTransactions(vm.organisationReference, params)
            .then(function (response) {
                vm.transactions = vm.transactions.concat(response.results);
                vm.pagedSet.page = response.page;

                vm.disableLoadMore = response.page >= response.totalPages;
            }, function (error) {
                vm.error = error;
                vm.disableLoadMore = true;
            })['finally'](function () {
            cleanUp();
        });
    }

    function cleanUp() {
        vm.loading = false;
        vm.loadingMore = false;

        if (angular.isDefined(vm.error)) {
            Notification.error({message: vm.error, delay: 5000});
        }
    }

    function search() {
        vm.pagedSet.page = 0;
        var params = getParams();
        vm.transactions = [];
        vm.pagedSet.page = 0;

        getTransactionList(params);

    }

    function clearSearch() {
        vm.fromDate = moment().subtract(7, "days").toDate();
        vm.toDate = moment().toDate();
        vm.transactionReference = undefined;
        vm.paymentMethodReference = undefined;
        vm.pending = false;
        vm.settled = false;
    }

    function getParams() {
        var params = {
            limit: vm.pagedSet.limit,
            page: (vm.pagedSet.page),
            sort: vm.sortField,
            fromDate: vm.fromDate,
            toDate: vm.toDate
        };
        if (vm.paymentMethodReference) params.paymentMethodReference = vm.paymentMethodReference;
        if (vm.transactionReference) params.transactionReference = vm.transactionReference;
        if (vm.pending && !vm.settled) params.status = "PENDING";
        if (!vm.pending && vm.settled) params.status = "SETTLED";

        return params;
    }

    function toggleJournalEntries(transaction) {

        if (!_.isUndefined(transaction.journalEntries)) {
            delete transaction.journalEntries;
        } else {

            vm.loadingJournals = true;

            _.forEach(vm.transactions, function (transaction) {
                delete transaction.journalEntries;
            });

            vm.error = undefined;


            accountService.getTransaction(transaction.transactionReference)
                .then(function (response) {
                        $log.debug(angular.toJson(response, true));
                        transaction.journalEntries = response.data.journalEntries;
                    },
                    function (error) {
                        vm.error = error;
                    })['finally'](function () {
                vm.loadingJournals = false;
            });
        }
    }

    function exportToCsv() {
        var exportHref = Excel.tableToCsv('#transactions');

        var link = document.createElement('a');
        link.download = 'transaction.csv';
        link.href = exportHref;
        link.click();

    }
}